import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCampaigns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/marketing/campaigns/campaign", { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    fetchCampaign(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/marketing/campaigns/campaign/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    addCampaign(ctx, campaignData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/marketing/campaigns/campaign", { campaign: campaignData })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
  },
};
