<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="campaignData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching data
      </h4>
      <div class="alert-body">
        No campaigns found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'campaigns' }"
        >
          Campaigns List
        </b-link>
        for other campaigns.
      </div>
    </b-alert>

    <template v-if="campaignData">
      <section id="dashboard-ecommerce">
        <b-card>
          <b-row
            align-v="center"
            no-gutters
          >
            <b-col>
              <h1>{{ campaignData.campaignName }}</h1>
            </b-col>
            <b-col>
              <span style="font-weight: bold">From</span>&nbsp;
              <span>{{ campaignData.rangeDate }}</span>
            </b-col>
            <b-col>
              <span style="font-weight: bold">Budget</span>&nbsp;
              <span>{{ campaignData.actualBudget }}</span>
            </b-col>
          </b-row>
        </b-card>
        <b-row class="match-height">
          <b-col
            xl="4"
            md="4"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
          <b-col
            xl="8"
            md="8"
          >
            <ecommerce-statistics :data="data.statisticsItems" />
          </b-col>
        </b-row>

        <b-row class="match-height">
          <!-- Revenue Report Card -->
          <b-col lg="8">
            <ecommerce-revenue-report :data="data.revenue" />
          </b-col>
          <!--/ Revenue Report Card -->

          <b-col lg="4">
            <b-row class="match-height">
              <!-- Bar Chart - Orders -->
              <b-col
                lg="6"
                md="3"
                cols="6"
              >
                <ecommerce-order-chart :data="data.statisticsOrder" />
              </b-col>
              <!--/ Bar Chart - Orders -->
              <b-col
                lg="6"
                md="3"
                cols="6"
              >
                <ecommerce-profit-chart :data="data.statisticsProfit" />
              </b-col>
              <b-col
                lg="12"
                md="6"
              >
                <campaign-expenses-chart :data="data.earningsChart" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="match-height">
          <!-- Company Table Card -->
          <b-col lg="12">
            <ecommerce-company-table :table-data="data.companyTable" />
          </b-col>
          <!--/ Company Table Card -->

          <!-- Browser States Card -->
          <b-col
            lg="6"
            md="6"
          >
            <ecommerce-browser-states />
          </b-col>
          <!--/ Browser States Card -->

          <!-- Browser States Card -->
          <b-col
            lg="6"
            md="6"
          >
            <ecommerce-browser-states-products />
          </b-col>
          <!--/ Browser States Card -->

          <!-- Company Table Card -->
          <b-col lg="12">
            <ecommerce-company-table-seconde :table-data="data.companyTable" />
          </b-col>
          <!--/ Company Table Card -->
        </b-row>
      </section>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
// eslint-disable-next-line object-curly-newline
import { BRow, BCol, BAlert, BLink, BCard } from "bootstrap-vue";
import campaignStoreModule from "../campaignStoreModule";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import EcommerceEarningsChart from "./EcommerceEarningsChart.vue";
import CampaignExpensesChart from "./CampaignExpensesChart.vue";
import EcommerceCompanyTable from "./EcommerceCompanyTable.vue";
import EcommerceCompanyTableSeconde from "./EcommerceCompanyTableSeconde.vue";
import EcommerceBrowserStates from "./EcommerceBrowserStates.vue";
import EcommerceBrowserStatesProducts from "./EcommerceBrowserStatesProducts.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,

    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    CampaignExpensesChart,
    EcommerceCompanyTable,
    EcommerceCompanyTableSeconde,
    EcommerceBrowserStates,
    EcommerceBrowserStatesProducts,
  },
  data() {
    return {
      data: {},
    };
  },
  setup() {
    const campaignData = ref(null);

    // eslint-disable-next-line camelcase
    const Campaign_APP_STORE_MODULE_NAME = "app-campaign";

    // Register module
    // eslint-disable-next-line curly
    if (!store.hasModule(Campaign_APP_STORE_MODULE_NAME))
      // eslint-disable-next-line nonblock-statement-body-position
      store.registerModule(Campaign_APP_STORE_MODULE_NAME, campaignStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(Campaign_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(Campaign_APP_STORE_MODULE_NAME);
      }
    });

    store
      .dispatch("app-campaign/fetchCampaign", {
        id: router.currentRoute.params.id,
      })
      // eslint-disable-next-line arrow-parens
      .then((response) => {
        campaignData.value = response.data;
      })
      // eslint-disable-next-line arrow-parens
      .catch((error) => {
        if (error.response.status === 404) {
          campaignData.value = undefined;
        }
      });

    return {
      campaignData,
    };
  },
  created() {
    // data
    // eslint-disable-next-line arrow-parens
    this.$http.get("/ecommerce/data").then((response) => {
      this.data = response.data;
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
